HashMap = (function() {

	// constructor
	return function(inithash) {

		// members
		var keyArray = []; // Keys
		var items = [];

		// public functions
		var put = function put(key, val) {

			if (!this.has(key)) {
				++this.length;
				keyArray.push(key);
			}
			items[key] = val;

		};

		var get = function get(key) {
			return items[key];
		};

		var values = function values(bFlattenHashes) {
			var aReturn = [],
				aVals,
				i = 0,
				j = 0;

			if (bFlattenHashes) {
				for (i in items) {
					if (items.hasOwnProperty(i)) {
						if (items[i] && items[i].type && items[i].type === "HashMap") {
							aVals = items[i].values(bFlattenHashes);
							for (j in aVals) {
								if (aVals.hasOwnProperty(j)) {
									aReturn.push(aVals[j]);
								}
							}
						} else {
							aReturn.push(items[i]);
						}
					}
				}
			} else {
				for (i in items) {
					if (items.hasOwnProperty(i)) {
						aReturn.push(items[i]);
					}
				}
			}
			return aReturn;
		};

		var keys = function keys() {
			return keyArray;
		};

		var remove = function remove(key) {
			var deletedItem;

			if (items[key] !== undefined) {
				deletedItem = items[key];
				delete items[key];
				keyArray.splice(keyArray.indexOf(key.toString()), 1);
				this.length--;
			}
			return deletedItem;
		};

		var clear = function clear() {
			items = [];
			keyArray.splice(0, this.length);
			this.length = 0;
		};

		var each = function each(fn, context) {
			var keyArrayLength = keyArray.length,
				i;

			for (i = 0; i < keyArrayLength; i++) {
				if (fn.apply(context || this, [keyArray[i], items[keyArray[i]], i])) {
					break;
				}
			}
		};

		var has = function has(key) {
			return items[key] ? true : false;
		};

		var getFirst = function getFirst() {
			return this.getByIndex(0);
		};

		var getByIndex = function getByIndex(idx) {
			if (keyArray.length > idx) {
				return get(keyArray[idx]);
			}

			return null;
		};

		var getFirstKey = function getFirstKey() {
			return this.getKeyByIndex(0);
		};

		var getKeyByIndex = function getKeyByIndex(idx) {
			if (keyArray.length > idx) {
				return keyArray[idx];
			}
			return null;

		};

		// private functions
		this.length = 0;
		this.type = "HashMap";
		this.keyArray = keyArray;

		// methods
		this.put = put;
		this.get = get;
		this.getFirst = getFirst;
		this.getByIndex = getByIndex;
		this.remove = remove;
		this.has = has;
		this.clear = clear;
		this.each = each;
		this.keys = keys;
		this.getFirstKey = getFirstKey;
		this.getKeyByIndex = getKeyByIndex;
		this.values = values;

		// put init object
		var key;
		for (key in inithash) {
			if (inithash.hasOwnProperty(key)) {
				this.put(key, inithash[key]);
			}
		}

		return this;
	};
}());
