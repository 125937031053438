/**
 * CONTENS Drag n Drop offset plugin
 * Adds drag-n-drop offset calculation feature to jquery ui droppable
 *
 * built for jQuery UI - v1.12.0
 */

"use strict";

(function($) {

	$.ui.ddmanager.prepareOffsets = function(t, event) {
		var i, j,
			m = $.ui.ddmanager.droppables[t.options.scope] || [],
			type = event ? event.type : null,
			list = (t.currentItem || t.element).find(":data(ui-droppable)").addBack();

		droppablesLoop: for (i = 0; i < m.length; i++) {
			if (m[i].options.disabled || (t && !m[i].accept.call(m[i].element[0],
					(t.currentItem || t.element)))) {
				continue;
			}

			for (j = 0; j < list.length; j++) {
				if (list[j] === m[i].element[0]) {
					m[i].proportions().height = 0;
					continue droppablesLoop;
				}
			}

			m[i].visible = m[i].element.css("display") !== "none";
			if (!m[i].visible) {
				continue;
			}

			if (type === "mousedown") {
				m[i]._activate.call(m[i], event);
			}

			m[i].offset = m[i].element.offset();
			m[i].proportions({
				width: m[i].element[0].offsetWidth,
				height: m[i].element[0].offsetHeight
			});

			/* added by Contens to calculate iframe offset */
			if (m[i].options.dropOffset && m[i].offset && typeof m[i].options.dropOffset === "function") {
				var dropOffset = m[i].options.dropOffset();
				m[i].offset.left += dropOffset.left;
				m[i].offset.top += dropOffset.top;
			}
			/* EOF: added by Contens to calculate iframe offset */
		}
	};

	$.ui.droppable.prototype._drop = function(event, custom) {

		var draggable = custom || $.ui.ddmanager.current,
			childrenIntersection = false;

		// Bail if draggable and droppable are same element
		if (!draggable || (draggable.currentItem ||
				draggable.element)[0] === this.element[0]) {
			return false;
		}

		this.element
			.find(":data(ui-droppable)")
			.not(".ui-draggable-dragging")
			.each(function() {
				var inst = $(this).droppable("instance"),
					instOffset = inst.element.offset();
				/* added by Contens to calculate iframe offset */
				if (inst.options.dropOffset && inst.offset && typeof inst.options.dropOffset === "function") {
					var dropOffset = inst.options.dropOffset();
					instOffset.left += dropOffset.left;
					instOffset.top += dropOffset.top;
				}
				/* EOF: added by Contens to calculate iframe offset */
				if (
					inst.options.greedy &&
					!inst.options.disabled &&
					inst.options.scope === draggable.options.scope &&
					inst.accept.call(
						inst.element[0], (draggable.currentItem || draggable.element)
					) &&
					$.ui.intersect(
						draggable,
						$.extend({}, inst, {
							offset: instOffset
						}),
						inst.options.tolerance, event
					)
				) {
					childrenIntersection = true;
					return false;
				}
			});

		if (childrenIntersection) {
			return false;
		}

		if (this.accept.call(this.element[0],
				(draggable.currentItem || draggable.element))) {
			this._removeActiveClass();
			this._removeHoverClass();

			this._trigger("drop", event, this.ui(draggable));
			return this.element;
		}

		return false;

	};

})(jQuery);
