import $ from 'jquery';
import 'jquery-ui';
import 'jstree';
import 'jquery.cookie';
import 'select2';
import 'cropper';
import 'event-source-polyfill';
import '@rwap/jquery-ui-touch-punch';
import 'jquery.scrollto';
import * as _ from 'lodash';
import moment from 'moment';

window.moment = moment;
window.jQuery = $;
window.$ = $;
window._ = _;

window.toastr = require("toastr");
window.prettydiff = require("prettydiff");

require("@accursoft/jquery-caret");
require("jquery.scrollintoview");
require("jquery-ui-timepicker-addon");

require('select2/dist/js/i18n/de');
require('select2/dist/js/i18n/en');
require('select2/dist/js/i18n/fr');
