/**
 * ### Contextmenu plugin
 *
 * Shows a context menu when a node is right-clicked.
 */
/* globals jQuery, define, exports, require, document */
(function(factory) {
	"use strict";
	if (typeof define === 'function' && define.amd) {
		define('jstree.cContextmenu', ['jquery', 'jstree'], factory);
	} else if (typeof exports === 'object') {
		factory(require('jquery'), require('jstree'));
	} else {
		factory(jQuery, jQuery.jstree);
	}
}(function($, jstree, undefined) {
	"use strict";

	if ($.jstree.plugins.cContextmenu) {
		return;
	}

	/**
	 * stores all defaults for the contextmenu plugin
	 * @name $.jstree.defaults.contextmenu
	 * @plugin contextmenu
	 */
	$.jstree.defaults.cContextmenu = {
		/**
		 * a boolean indicating if the node should be selected when the context menu is invoked on it. Defaults to `true`.
		 * @name $.jstree.defaults.contextmenu.select_node
		 * @plugin contextmenu
		 */
		select_node: false,
		/**
		 * a boolean indicating if the menu should be shown aligned with the node. Defaults to `true`, otherwise the mouse coordinates are used.
		 * @name $.jstree.defaults.contextmenu.show_at_node
		 * @plugin contextmenu
		 */
		show_at_node: true,
		/**
		 * an object of actions, or a function that accepts a node and a callback function and calls the callback function with an object of actions available for that node (you can also return the items too).
		 *
		 * Each action consists of a key (a unique name) and a value which is an object with the following properties (only label and action are required):
		 *
		 * * `separator_before` - a boolean indicating if there should be a separator before this item
		 * * `separator_after` - a boolean indicating if there should be a separator after this item
		 * * `_disabled` - a boolean indicating if this action should be disabled
		 * * `label` - a string - the name of the action (could be a function returning a string)
		 * * `action` - a function to be executed if this item is chosen
		 * * `icon` - a string, can be a path to an icon or a className, if using an image that is in the current directory use a `./` prefix, otherwise it will be detected as a class
		 * * `shortcut` - keyCode which will trigger the action if the menu is open (for example `113` for rename, which equals F2)
		 * * `shortcut_label` - shortcut label (like for example `F2` for rename)
		 *
		 * @name $.jstree.defaults.contextmenu.items
		 * @plugin contextmenu
		 */
		items: function() { // Could be an object directly
			return {
				"create": {
					"separator_before": false,
					"separator_after": true,
					"_disabled": false, // (this.check("create_node", data.reference, {}, "last")),
					"label": "Create",
					"action": function(data) {
						var inst = $.jstree.reference(data.reference),
							obj = inst.get_node(data.reference);
						inst.create_node(obj, {}, "last", function(new_node) {
							try {
								inst.edit(new_node);
							} catch (ex) {
								setTimeout(function() {
									inst.edit(new_node);
								}, 0);
							}
						});
					}
				},
				"rename": {
					"separator_before": false,
					"separator_after": false,
					"_disabled": false, // (this.check("rename_node", data.reference, this.get_parent(data.reference), "")),
					"label": "Rename",
					/* !
					"shortcut"			: 113,
					"shortcut_label"	: 'F2',
					"icon"				: "glyphicon glyphicon-leaf",
					*/
					"action": function(data) {
						var inst = $.jstree.reference(data.reference),
							obj = inst.get_node(data.reference);
						inst.edit(obj);
					}
				},
				"remove": {
					"separator_before": false,
					"icon": false,
					"separator_after": false,
					"_disabled": false, // (this.check("delete_node", data.reference, this.get_parent(data.reference), "")),
					"label": "Delete",
					"action": function(data) {
						var inst = $.jstree.reference(data.reference),
							obj = inst.get_node(data.reference);
						if (inst.is_selected(obj)) {
							inst.delete_node(inst.get_selected());
						} else {
							inst.delete_node(obj);
						}
					}
				},
				"ccp": {
					"separator_before": true,
					"icon": false,
					"separator_after": false,
					"label": "Edit",
					"action": false,
					"submenu": {
						"cut": {
							"separator_before": false,
							"separator_after": false,
							"label": "Cut",
							"action": function(data) {
								var inst = $.jstree.reference(data.reference),
									obj = inst.get_node(data.reference);
								if (inst.is_selected(obj)) {
									inst.cut(inst.get_top_selected());
								} else {
									inst.cut(obj);
								}
							}
						},
						"copy": {
							"separator_before": false,
							"icon": false,
							"separator_after": false,
							"label": "Copy",
							"action": function(data) {
								var inst = $.jstree.reference(data.reference),
									obj = inst.get_node(data.reference);
								if (inst.is_selected(obj)) {
									inst.copy(inst.get_top_selected());
								} else {
									inst.copy(obj);
								}
							}
						},
						"paste": {
							"separator_before": false,
							"icon": false,
							"_disabled": function(data) {
								return !$.jstree.reference(data.reference).can_paste();
							},
							"separator_after": false,
							"label": "Paste",
							"action": function(data) {
								var inst = $.jstree.reference(data.reference),
									obj = inst.get_node(data.reference);
								inst.paste(obj);
							}
						}
					}
				}
			};
		}
	};

	$.jstree.plugins.cContextmenu = function(options, parent) {
		this.bind = function() {
			parent.bind.call(this);

			var last_ts = 0,
				cto = null,
				contextLaunched = false,
				ex, ey, sSelector = ".jstree-anchor";
			// if wholerow plugin is enabled we want the contextmenu to be fired for the entire row not just the anchor tag
			if (parent._data.hasOwnProperty('wholerow')) {
				sSelector = '.jstree-node';
			}

			$(this.element).find('a').on('contextmenu', function(event) {
				$(this).css('-webkit-user-select', 'none');
				$(this).css('-webkit-touch-callout', 'none');
				event.preventDefault();
				event.stopPropagation();
				return false;
			});

			$(this.element).css('-webkit-user-select', 'none');
			$(this.element).css('-webkit-touch-callout', 'none');
			$(this.element).find('a').css('-webkit-user-select', 'none');
			$(this.element).find('a').css('-webkit-touch-callout', 'none');

			function _eventFromEvent(origEvent, eventName, options) {
				var tmp = $.Event(eventName, {
					metaKey: origEvent.metaKey,
					ctrlKey: origEvent.ctrlKey,
					altKey: origEvent.altKey,
					shiftKey: origEvent.shiftKey
				});
				$.extend(true, tmp, options);
				$(origEvent.target).trigger(tmp);
			}

			this.element
				.on("contextmenu.jstree", sSelector, $.proxy(function(e, data) {
					e.preventDefault();
					e.stopImmediatePropagation(); // prevent from bubbling up to parent node
					e.stopPropagation();

					last_ts = e.ctrlKey ? +new Date() : 0;
					if (data || cto) {
						last_ts = (+new Date()) + 10000;
					}
					if (cto) {
						clearTimeout(cto);
						cto = 0;
					}
					if (!this.is_loading(e.currentTarget)) {
						this.show_contextmenu(e.currentTarget, e.pageX, e.pageY, e);
					}
				}, this))
				.on("click.jstree", sSelector, $.proxy(function() {
					if (this._data.cContextmenu.visible && (!last_ts || (+new Date()) - last_ts > 250)) { // work around safari & macOS ctrl+click
						$.vakata.cContext.hide();
					}
					last_ts = 0;
				}, this))
				.on("touchstart.jstree", sSelector, function(e) {
					contextLaunched = false;
					if (!e.originalEvent || !e.originalEvent.changedTouches || !e.originalEvent.changedTouches[0]) {
						return;
					}
					ex = e.touches[0].pageX;
					ey = e.touches[0].pageY;
					if (!cto) {
						e.stopImmediatePropagation(); // prevent from bubbling up to parent node
						e.stopPropagation();
						cto = setTimeout(function() {
							if (cto) {
								contextLaunched = true;
								cto = 0;
								$(e.target).closest('a').trigger('contextmenu.jstree', true);
							}
						}, 400);
					}
				})
				.on('touchmove.vakata.jstree', function(e) {
					if (cto && e.originalEvent && e.originalEvent.changedTouches && e.originalEvent.changedTouches[0] && (Math.abs(ex - e.originalEvent.changedTouches[0].pageX) > 50 || Math.abs(ey - e.originalEvent.changedTouches[0].pageY) > 50)) {
						clearTimeout(cto);
						cto = 0;
					}
				})
				.on('touchend.jstree', function(e) {
					if (contextLaunched === true) {
						e.preventDefault();
						e.stopImmediatePropagation(); // prevent from bubbling up to parent node
						e.stopPropagation();
						return;
					}
					if (cto) {
						clearTimeout(cto);
						cto = 0;
						// For mobile devices we need to fire a click event.
						e.preventDefault();
						_eventFromEvent(e, 'click');
					}
				});

			$(document).on("context_hide.vakata.jstree", $.proxy(function(e, data) {
				this._data.cContextmenu.visible = false;
				$(data.reference).removeClass('jstree-context');
			}, this));
		};
		this.teardown = function() {
			if (this._data.cContextmenu.visible) {
				$.vakata.cContext.hide();
			}
			parent.teardown.call(this);
		};

		/**
		 * prepare and show the context menu for a node
		 * @name show_contextmenu(obj [, x, y])
		 * @param {mixed} obj the node
		 * @param {Number} x the x-coordinate relative to the document to show the menu at
		 * @param {Number} y the y-coordinate relative to the document to show the menu at
		 * @param {Object} e the event if available that triggered the contextmenu
		 * @plugin contextmenu
		 * @trigger show_contextmenu.jstree
		 */
		this.show_contextmenu = function(obj, x, y, e) {
			obj = this.get_node(obj);
			if (!obj || obj.id === $.jstree.root) {
				return false;
			}
			var s = this.settings.cContextmenu,
				d = this.get_node(obj, true),
				a = d.children(".jstree-anchor"),
				o = false,
				i = false;
			if (s.show_at_node || x === undefined || y === undefined) {
				o = a.offset();
				x = o.left;
				y = o.top + this._data.core.li_height;
			}
			if (this.settings.cContextmenu.select_node && !this.is_selected(obj)) {
				this.activate_node(obj, e);
			}

			i = s.items;
			if (s.onshowmenu) {
				this._data.cContextmenu.onshowmenu = s.onshowmenu;
			}
			if ($.isFunction(i)) {
				i = i.call(this, obj, $.proxy(function(i) {
					this._show_contextmenu(obj, x, y, i);
				}, this));
			}
			if ($.isPlainObject(i)) {

				this._show_contextmenu(obj, x, y, i);
			}
		};
		/**
		 * show the prepared context menu for a node
		 * @name _show_contextmenu(obj, x, y, i)
		 * @param {mixed} obj the node
		 * @param {Number} x the x-coordinate relative to the document to show the menu at
		 * @param {Number} y the y-coordinate relative to the document to show the menu at
		 * @param {Number} i the object of items to show
		 * @plugin contextmenu
		 * @trigger show_contextmenu.jstree
		 * @private
		 */
		this._show_contextmenu = function(obj, x, y, i) {
			var d = this.get_node(obj, true),
				a = d.children(".jstree-anchor"),
				st;
			$(document).one("context_show.vakata.jstree", $.proxy(function(e, data) {
				var cls = 'jstree-contextmenu jstree-' + this.get_theme() + '-contextmenu';
				$(data.element).addClass(cls);
			}, this));

			this._data.cContextmenu.visible = true;
			st = $.extend(true, {}, i);
			if (this._data.cContextmenu.onshowmenu) {

				if (this._data.cContextmenu.onshowmenu(st, d, this._data.cContextmenu, this) === false) {
					return false;
				}

				if ($.isEmptyObject(st)) {
					return false;
				}
			}
			$.vakata.cContext.show(a, {
				'x': x,
				'y': y
			}, st);
			/**
			 * triggered when the contextmenu is shown for a node
			 * @event
			 * @name show_contextmenu.jstree
			 * @param {Object} node the node
			 * @param {Number} x the x-coordinate of the menu relative to the document
			 * @param {Number} y the y-coordinate of the menu relative to the document
			 * @plugin contextmenu
			 */
			this.trigger('show_contextmenu', {
				"node": obj,
				"x": x,
				"y": y
			});
		};
	};

	// contextmenu helper
	(function($) {
		var right_to_left = false,
			vakata_context = {
				element: false,
				reference: false,
				position_x: 0,
				position_y: 0,
				items: [],
				html: "",
				is_visible: false
			};

		$.vakata.cContext = {
			settings: {
				hide_onmouseleave: 0,
				icons: true
			},
			_trigger: function(event_name) {
				$(document).triggerHandler("context_" + event_name + ".vakata", {
					"reference": vakata_context.reference,
					"element": vakata_context.element,
					"position": {
						"x": vakata_context.position_x,
						"y": vakata_context.position_y
					}
				});
			},
			_execute: function(i) {
				i = vakata_context.items[i];
				return i && (!i._disabled || ($.isFunction(i._disabled) && !i._disabled({
					"item": i,
					"reference": vakata_context.reference,
					"element": vakata_context.element
				}))) && i.action ? i.action.call(null, {
					"item": i,
					"reference": vakata_context.reference,
					"element": vakata_context.element,
					"position": {
						"x": vakata_context.position_x,
						"y": vakata_context.position_y
					}
				}) : false;
			},
			_parse: function(o, is_callback) {
				if (!o) {
					return false;
				}
				if (!is_callback) {
					vakata_context.html = "";
					vakata_context.items = [];
				}
				var str = "",
					sep = false,
					tmp;

				if (is_callback) {
					str += "<" + "ul>";
				}
				$.each(o, function(i, val) {
					if (!val) {
						return true;
					}
					vakata_context.items.push(val);
					if (!sep && val.separator_before) {
						str += "<" + "li class='vakata-context-separator'><" + "a href='#' " + ($.vakata.cContext.settings.icons ? '' : 'style="margin-left:0px;"') + ">&#160;<" + "/a><" + "/li>";
					}
					sep = false;
					str += "<" + "li class='" + (val._class || "") + (val._disabled === true || ($.isFunction(val._disabled) && val._disabled({
						"item": val,
						"reference": vakata_context.reference,
						"element": vakata_context.element
					})) ? " vakata-contextmenu-disabled " : "") + "' " + (val.shortcut ? " data-shortcut='" + val.shortcut + "' " : '') + ">";
					str += "<" + "a href='#' rel='" + (vakata_context.items.length - 1) + "' id='" + val.id + "'>";
					if ($.vakata.cContext.settings.icons) {
						str += "<" + "i ";
						if (val.icon) {
							if (val.icon.indexOf("/") !== -1 || val.icon.indexOf(".") !== -1) {
								str += " style='background:url(\"" + val.icon + "\") center center no-repeat' ";
							} else {
								str += " class='" + val.icon + "' ";
							}
						} else if (val.iconclass) {
							str += " class='con-icon con-icon-" + val.iconclass + "' ";
						}
						str += "><" + "/i><" + "span class='vakata-contextmenu-sep'>&#160;<" + "/span>";
					}
					if (val.label) {
						str += ($.isFunction(val.label) ? val.label({
							"item": i,
							"reference": vakata_context.reference,
							"element": vakata_context.element
						}) : val.label);
					} else if (val.title) {
						str += val.title;
					}
					str += (val.shortcut ? ' <span class="vakata-contextmenu-shortcut vakata-contextmenu-shortcut-' + val.shortcut + '">' + (val.shortcut_label || '') + '</span>' : '');
					str += "<" + "/a>";
					if (val.submenu) {
						tmp = $.vakata.cContext._parse(val.submenu, true);
						if (tmp) {
							str += tmp;
						}
					}
					str += "<" + "/li>";
					if (val.separator_after) {
						str += "<" + "li class='vakata-context-separator'><" + "a href='#' " + ($.vakata.cContext.settings.icons ? '' : 'style="margin-left:0px;"') + ">&#160;<" + "/a><" + "/li>";
						sep = true;
					}
				});
				str = str.replace(/<li class\='vakata-context-separator'\><\/li\>$/, "");
				if (is_callback) {
					str += "</ul>";
				}
				/**
				 * triggered on the document when the contextmenu is parsed (HTML is built)
				 * @event
				 * @plugin contextmenu
				 * @name context_parse.vakata
				 * @param {jQuery} reference the element that was right clicked
				 * @param {jQuery} element the DOM element of the menu itself
				 * @param {Object} position the x & y coordinates of the menu
				 */
				if (!is_callback) {
					vakata_context.html = str;
					$.vakata.cContext._trigger("parse");
				}
				return str.length > 10 ? str : false;
			},
			_show_submenu: function(o) {
				o = $(o);
				if (!o.length || !o.children("ul").length) {
					return;
				}
				var e = o.children("ul"),
					xl = o.offset().left,
					x = xl + o.outerWidth(),
					y = o.offset().top,
					w = e.width(),
					h = e.height(),
					dw = $(window).width() + $(window).scrollLeft(),
					dh = $(window).height() + $(window).scrollTop();
				// може да се спести е една проверка - дали няма някой от класовете вече нагоре
				if (right_to_left) {
					o[x - (w + 10 + o.outerWidth()) < 0 ? "addClass" : "removeClass"]("vakata-context-left");
				} else {
					o[x + w > dw && xl > dw - x ? "addClass" : "removeClass"]("vakata-context-right");
				}
				if (y + h + 10 > dh) {
					e.css("bottom", "-1px");
				}

				// if does not fit - stick it to the side
				if (o.hasClass('vakata-context-right')) {
					if (xl < w) {
						e.css("margin-right", xl - w);
					}
				} else {
					if (dw - x < w) {
						e.css("margin-left", dw - x - w);
					}
				}

				e.show();
			},
			show: function(reference, position, data) {
				var o, e, x, y, w, h, dw, dh, cond = true;
				/*
					-before removing this make sure you test that the THIS contexmenu is not displayed
						while another contextmenu in the system is visible.
					1) open an object contextmenu or any other contextmenu
					2) attempt to open the tree context menu - if both are open simultaneoulsy you screwed up!
					3) revert your changes!
					4) try something else.
				*/
				$(document.body).trigger("click");

				if (vakata_context.element && vakata_context.element.length) {
					vakata_context.element.width('');
				}
				switch (cond) {
					case (!position && !reference):
						return false;
					case (!!position && !!reference):
						vakata_context.reference = reference;
						vakata_context.position_x = position.x;
						vakata_context.position_y = position.y;
						break;
					case (!position && !!reference):
						vakata_context.reference = reference;
						o = reference.offset();
						vakata_context.position_x = o.left + reference.outerHeight();
						vakata_context.position_y = o.top;
						break;
					case (!!position && !reference):
						vakata_context.position_x = position.x;
						vakata_context.position_y = position.y;
						break;
				}
				if (!!reference && !data && $(reference).data('vakata_contextmenu')) {
					data = $(reference).data('vakata_contextmenu');
				}
				if ($.vakata.cContext._parse(data)) {
					vakata_context.element.html(vakata_context.html);
				}
				if (vakata_context.items.length) {
					vakata_context.element.appendTo(document.body);
					e = vakata_context.element;
					x = vakata_context.position_x;
					y = vakata_context.position_y;
					w = e.width();
					h = e.height();
					dw = $(window).width() + $(window).scrollLeft();
					dh = $(window).height() + $(window).scrollTop();
					if (right_to_left) {
						x -= (e.outerWidth() - $(reference).outerWidth());
						if (x < $(window).scrollLeft() + 20) {
							x = $(window).scrollLeft() + 20;
						}
					}
					if (x + w + 20 > dw) {
						x = dw - (w + 20);
					}
					if (y + h + 20 > dh) {
						y = dh - (h + 20);
					}

					vakata_context.element
						.css({
							"left": x,
							"top": y
						})
						.show()
						.find('a').first().focus().parent().addClass("vakata-context-hover");
					vakata_context.is_visible = true;
					vakata_context.element.trigger('getZIndex');
					/**
					 * triggered on the document when the contextmenu is shown
					 * @event
					 * @plugin contextmenu
					 * @name context_show.vakata
					 * @param {jQuery} reference the element that was right clicked
					 * @param {jQuery} element the DOM element of the menu itself
					 * @param {Object} position the x & y coordinates of the menu
					 */
					$.vakata.cContext._trigger("show");
				}
			},
			hide: function() {
				if (vakata_context.is_visible) {
					vakata_context.element.hide().find("ul").hide().end().find(':focus').blur().end().detach();
					vakata_context.is_visible = false;
					/**
					 * triggered on the document when the contextmenu is hidden
					 * @event
					 * @plugin contextmenu
					 * @name context_hide.vakata
					 * @param {jQuery} reference the element that was right clicked
					 * @param {jQuery} element the DOM element of the menu itself
					 * @param {Object} position the x & y coordinates of the menu
					 */
					$.vakata.cContext._trigger("hide");
				}
			}
		};
		$(function() {
			right_to_left = $(document.body).css("direction") === "rtl";
			var to = false;
			vakata_context.element = $("<ul class='vakata-context'></ul>");
			vakata_context.element
				.on('setZIndex', function(event, value) {
					vakata_context.element.css("z-index", value);
				})
				.on("mouseenter", "li", function(e) {
					e.stopImmediatePropagation();

					if ($.contains(this, e.relatedTarget)) {
						return;
					}

					if (to) {
						clearTimeout(to);
					}
					vakata_context.element.find(".vakata-context-hover").removeClass("vakata-context-hover").end();

					$(this)
						.siblings().find("ul").hide().end().end()
						.parentsUntil(".vakata-context", "li").addBack().addClass("vakata-context-hover");
					$.vakata.cContext._show_submenu(this);
				})
				.on("mouseleave", "li", function(e) {
					if ($.contains(this, e.relatedTarget)) {
						return;
					}
					$(this).find(".vakata-context-hover").addBack().removeClass("vakata-context-hover");
				})
				.on("mouseleave", function() {
					$(this).find(".vakata-context-hover").removeClass("vakata-context-hover");
					if ($.vakata.cContext.settings.hide_onmouseleave) {
						to = setTimeout(
							(function() {
								return function() {
									$.vakata.cContext.hide();
								};
							}(this)), $.vakata.cContext.settings.hide_onmouseleave);
					}
				})
				.on("click", "a", function(e) {
					e.preventDefault();
					if (!$(this).blur().parent().hasClass("vakata-context-disabled") && $.vakata.cContext._execute($(this).attr("rel")) !== false) {
						$.vakata.cContext.hide();
					}
				})
				.on('keydown', 'a', function(e) {
					var o = null;
					switch (e.which) {
						case 13:
						case 32:
							e.type = "mouseup";
							e.preventDefault();
							$(e.currentTarget).trigger(e);
							break;
						case 37:
							if (vakata_context.is_visible) {
								vakata_context.element.find(".vakata-context-hover").last().closest("li").first().find("ul").hide().find(".vakata-context-hover").removeClass("vakata-context-hover").end().end().children('a').focus();
								e.stopImmediatePropagation();
								e.preventDefault();
							}
							break;
						case 38:
							if (vakata_context.is_visible) {
								o = vakata_context.element.find("ul:visible").addBack().last().children(".vakata-context-hover").removeClass("vakata-context-hover").prevAll("li:not(.vakata-context-separator)").first();
								if (!o.length) {
									o = vakata_context.element.find("ul:visible").addBack().last().children("li:not(.vakata-context-separator)").last();
								}
								o.addClass("vakata-context-hover").children('a').focus();
								e.stopImmediatePropagation();
								e.preventDefault();
							}
							break;
						case 39:
							if (vakata_context.is_visible) {
								vakata_context.element.find(".vakata-context-hover").last().children("ul").show().children("li:not(.vakata-context-separator)").removeClass("vakata-context-hover").first().addClass("vakata-context-hover").children('a').focus();
								e.stopImmediatePropagation();
								e.preventDefault();
							}
							break;
						case 40:
							if (vakata_context.is_visible) {
								o = vakata_context.element.find("ul:visible").addBack().last().children(".vakata-context-hover").removeClass("vakata-context-hover").nextAll("li:not(.vakata-context-separator)").first();
								if (!o.length) {
									o = vakata_context.element.find("ul:visible").addBack().last().children("li:not(.vakata-context-separator)").first();
								}
								o.addClass("vakata-context-hover").children('a').focus();
								e.stopImmediatePropagation();
								e.preventDefault();
							}
							break;
						case 27:
							$.vakata.cContext.hide();
							e.preventDefault();
							break;
						default:
							// console.log(e.which);
							break;
					}
				})
				.on('keydown', function(e) {
					e.preventDefault();
					var a = vakata_context.element.find('.vakata-contextmenu-shortcut-' + e.which).parent();
					if (a.parent().not('.vakata-context-disabled')) {
						a.click();
					}
				});
			$(document)
				.on("mousedown.vakata.jstree click.vakata.jstree scroll.vakata.jstree hideContext.vakata.jstree", function(e) {
					if (vakata_context.is_visible && vakata_context.element[0] !== e.target && !$.contains(vakata_context.element[0], e.target)) {
						$.vakata.cContext.hide();
					}
				})
				.on("context_show.vakata.jstree", function() {
					vakata_context.element.find("li:has(ul)").children("a").addClass("vakata-context-parent");
					if (right_to_left) {
						vakata_context.element.addClass("vakata-context-rtl").css("direction", "rtl");
					}
					// also apply a RTL class?
					vakata_context.element.find("ul").hide().end();
				});
		});
	}($));
}));

// override the standard state saving from localStorage to sessionStorage
(function(factory) {
	"use strict";
	if (typeof define === 'function' && define.amd) {
		define(['jquery'], factory);
	} else if (typeof module !== 'undefined' && module.exports) {
		module.exports = factory(require('jquery'));
	} else {
		factory(jQuery);
	}
}(function($, undefined) {
	"use strict";

	(function($, undefined) {
		$.vakata.storage = {
			// simply specifying the functions in FF throws an error
			set: function(key, val) {
				return window.sessionStorage.setItem(key, val);
			},
			get: function(key) {
				return window.sessionStorage.getItem(key);
			},
			del: function(key) {
				return window.sessionStorage.removeItem(key);
			}
		};
	}($));
}));
