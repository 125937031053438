(function($) {

	$.extend({

		newObjectFromFile: function(fileData, fieldname, class_id, fnSaveCallback) {
			var oController = $.parseControllerURL('callback.file.subobjectfileupload'),
				xhr = new XMLHttpRequest();

			xhr.open("POST", $.getControllerURL(oController.sController, oController.sEvent, {}), false);

			xhr.onerror = function(e) {
				if (window.console) {
					window.console.log(e);
				}
			};

			var formdata = new FormData();
			formdata.append("upload", fileData, fileData.name);
			formdata.append("name", fileData.name);
			formdata.append("size", fileData.size);
			formdata.append("class_id", class_id);
			formdata.append("sourcefieldname", fieldname);

			xhr.send(formdata);

			if (xhr.status == 200) {
				var objectID = Number(new Date()),
					response = JSON.parse(xhr.responseText),
					pageid = ($('#cms-sectionsswitch ul li.active').prop("id") === 'copages') ? pageid = window.cms.cBaseApp.getPageID() : 0;

				if (!response.success) {
					// error "No rules for this class id" - ignore and return
					if (parseInt(response.resultcode, 10) === 3) {
						return;
					}

					if (parseInt(response.resultcode, 10) === 2) {
						$('<div id="dialog-message" title="' + window.cms.i18n.system.text.fileextensionnotallowed + '">' + window.cms.i18n.system.text.selectanotherfileextension + '</div>').cDialog({
							modal: true,
							resizable: false,
							stack: true
						});
					} else {
						$('<div id="dialog-message" title="' + window.cms.i18n.system.text.errortext + '">' + window.cms.i18n.system.text.cannotchangedata + '</div>').cDialog({
							modal: true,
							resizable: false,
							stack: true
						});
					}
					return;
				}

				response.fileInfo = fileData;

				$(document.body).trigger('loadaction', ['class', {
					id: objectID,
					object_id: 0,
					class_id: response.targetinfo.class_id,
					location_id: '',
					page_id: pageid,
					datalang_id: window.cms.cBaseApp.getLangID(),
					lang_id: window.cms.cBaseApp.getLangID(),
					posId: 0,
					viewmode: 7
				}, {
					title: window.cms.i18n.workspace.text.insertnew || window.cms.i18n.system.text.txtnew
				}]);

				$('#objectsedit' + objectID).on('afterLoad.cwindow', function() {
					$(this).find('.ui-form-row-multiusage:first').on('afterInit.uploadrowtype', function() {
						$(this).trigger('multiusage.setRow', response);
					});
				});

				$('#objectsedit' + objectID).bind('saveSuccess.form', fnSaveCallback);
			}
		}

	});
}(jQuery));
